import type { FunctionComponent } from 'react'
import type { LogoProps } from '../Logo'
import styles from './Logo.module.sass'

export const Logo: FunctionComponent<LogoProps> = ({ variant }) => {
	return variant === 'small' ? (
		<img
			className={styles.small}
			src="https://data.eu.cntmbr.com/goodlok/goodlok/e1f62007-d3a4-42d7-b153-5c979846cef0.png"
			alt="Logo"
		/>
	) : (
		// @TODO Should be vector :/
		<img
			className={styles.large}
			src="https://data.eu.cntmbr.com/goodlok/goodlok/ba6b3469-5e57-4310-bfe2-4fc440280b75.png"
			alt="Logo"
		/>
	)
}
